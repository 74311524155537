import React from 'react';
import { Link } from 'gatsby';

import Layout from '../components/Layout';
import SEO from '../components/SEO';
import BasicPage from '../templates/BasicPage';
import Title from '../components/shared/Title';
import StrategicMoney from '../components/about/StrategicMoney';
import WhereWeInvest from '../components/about/WhereWeInvest';

const topTitle = `About`;
const bylines = [
  `Primera Capital has funded some of the world’s most transformative technology companies over the past two decades. We invest in extraordinary products, opportune markets, and daring entrepreneurs with unwavering visions. Past investments include VMWare (acquired by EMC), Chegg (CHGG), and Fandango (acquired by Comcast).`,
  `Our creed is to differentiate between hype and authentic value, diagnose underlying trends, and cultivate rich networks so that we can deploy our experience and resources to help entrepreneurs realize their dreams.`,
];

const AboutPage = () => (
  <Layout>
    <SEO pageTitle="About" description="" />
    <BasicPage>
      <Title title={topTitle} bylines={bylines} />
      <StrategicMoney />
      <WhereWeInvest />
    </BasicPage>
  </Layout>
);

export default AboutPage;
