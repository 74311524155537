import * as React from 'react';
import * as styles from './strategicMoney.module.scss';

import classnames from 'classnames';

import committedImage from './images/committed.inline.svg';
import communityImage from './images/community.inline.svg';
import ecosystemsImage from './images/ecosystems.inline.svg';
import experienceImage from './images/experience.inline.svg';
import flexibleImage from './images/flexible.inline.svg';
import visionImage from './images/vision.inline.svg';

const icons = [
  {
    title: `Flexible`,
    image: flexibleImage,
    headline: `We're Sharpshooters`,
    textContent: `Our $100M fund is structured so that we can deploy capital when needed, without the obligation to provide returns to our Limited Partners on a fund lifetime oriented basis. This means that we have complete flexibility when making investments, only deploying capital to the most exciting opportunities.`,
  },
  {
    title: `Community`,
    image: communityImage,
    headline: `We’re a Tightknit Community`,
    textContent: `When we fund a new company, they become a part of the Primera network. Our industry connections are vast and deep. We open the doors to high-level executives at some of the world’s largest enterprises, and we help create new business opportunities by leveraging Primera alumni and other portfolio companies.`,
  },
  {
    title: `Experience`,
    image: experienceImage,
    headline: `We’ve Been There`,
    textContent: `Our team has significant operational experience, having founded six companies from scratch that have collectively created billions of dollars in new market value. We understand finance, but more importantly, we understand what it’s like to be a startup CEO and the frequent challenges that growing technology companies face.`,
  },
  {
    title: `Committed`,
    image: committedImage,
    headline: `We Live for Our Entrepreneurs`,
    textContent: `Entrepreneurs come first, investors come second. We take this to heart by structuring our term sheets so that they are highly favorable to our founders, with maximum upside potential for the startup and limited protective provisions against downside risk for Primera.`,
  },
  {
    title: `Vision`,
    image: visionImage,
    headline: `We Invest Strategic Money`,
    textContent: `We don’t just inject money and sit back. We take an active role in helping our portfolio companies achieve their visions by leveraging our operational knowledge, board experience, and network connections.`,
  },
  {
    title: `Ecosystems`,
    image: ecosystemsImage,
    headline: `We Believe in Ecosystems`,
    textContent: `Ecosystem-based businesses create previously unrealized connections between nodes in a network. They are notoriously difficult businesses to build, but that’s why we like them.`,
  },
];

interface Props {}
interface State {
  activeDetail: number;
}

export default class StrategicMoney extends React.Component<Props, State> {
  private interval: any;
  constructor(props: Props) {
    super(props);
    this.state = {
      activeDetail: 0,
    };
  }

  componentDidMount() {
    this.startInterval();
  }

  componentWillUnmount() {
    window.clearInterval(this.interval);
  }

  startInterval = () => {
    this.interval = window.setInterval(() => {
      this.setActiveDetail();
    }, 4000);
  };

  setActiveDetail = (
    index: number | null = null,
    stopPlaying: boolean = false,
  ) => {
    if (index !== null) {
      window.clearInterval(this.interval);
      return this.setState({
        activeDetail: index,
      });
    }
    let nextActiveDetail = this.state.activeDetail + 1;
    nextActiveDetail = nextActiveDetail >= icons.length ? 0 : nextActiveDetail;
    this.setState({
      activeDetail: nextActiveDetail,
    });
  };

  render() {
    return (
      <div className={styles.container}>
        <h4 className={styles.heading}>
          {icons[this.state.activeDetail].headline}
        </h4>
        <p className={styles.byline}>
          {icons[this.state.activeDetail].textContent}
        </p>
        <div className={styles.iconsContainer}>
          {icons.map((icon, index) => {
            const SVG = icon.image;
            return (
              <div
                onMouseEnter={() => this.setActiveDetail(index, true)}
                onClick={() => this.setActiveDetail(index, true)}
                onMouseLeave={() => this.startInterval()}
                key={icon.title}
                className={classnames(
                  styles.iconContainer,
                  this.state.activeDetail === index ? styles.active : '',
                )}
              >
                <div className={styles.box}>
                  <SVG />
                </div>
                <span className={styles.iconTitle}>{icon.title}</span>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}
