import * as React from 'react';
import * as styles from './whereWeInvest.module.scss';

import geographyImage from './images/geography.svg';
import stagesImage from './images/stages.svg';
import sectorsImage from './images/sectors.svg';

const details = [
  {
    title: `Sectors`,
    byline: `We invest in all sectors, including:`,
    items: [
      `Enterprise/SaaS`,
      `Consumer/Retail`,
      `Healthcare`,
      `Security`,
      `Ecommerce`,
      `Education`,
    ],
    image: sectorsImage,
  },
  {
    title: `Stages`,
    byline: `We invest in any stage from seed to growth, including:`,
    items: [`Seed / Series A`, `Venture`, `Growth`],
    image: stagesImage,
  },
  {
    title: `Geography`,
    byline: `We’ve partnered with entrepreneurs around the world, including:`,
    items: [
      `Silicon Valley / SF Bay Area`,
      `New York`,
      `Los Angeles`,
      `Canada`,
      `Israel`,
      `India`,
      `France`,
    ],
    image: geographyImage,
  },
];

export default () => (
  <div className={styles.container}>
    <h4 className={styles.heading}>Where We Invest</h4>
    <div className={styles.details}>
      {details.map(item => (
        <div key={item.title} className={styles.itemContainer}>
          <img className={styles.icon} src={item.image} />
          <h6 className={styles.itemTitle}>{item.title}</h6>
          <p className={styles.itemByline}>{item.byline}</p>
          <ul className={styles.listItems}>
            {item.items.map(listItem => (
              <li key={listItem} className={styles.listItem}>
                {listItem}
              </li>
            ))}
          </ul>
        </div>
      ))}
    </div>
  </div>
);
